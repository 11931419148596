<template>
  <medication-allergies-template
    :medication-allergies="medicationAllergies"
    :other-medication-allergies="otherMedicationAllergies"
    :medication-tetracyclin-visible="medicationTetracyclinVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import MedicationAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/medication-allergies/MedicationAllergiesTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { includes } from '@/modules/questionnaire/api/helpers';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { ACNE_TREATMENT, OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  field('acneTreatments'),
  requiredArrayField('medicationAllergies'),
  requiredField('otherMedicationAllergies')
];

export default {
  name: 'MedicationAllergies',
  components: {
    MedicationAllergiesTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    medicationTetracyclinVisible() {
      return !this.acneTreatments.includes(ACNE_TREATMENT.TETRACYCLINE);
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'otherMedicationAllergies') {
        return includes(this.medicationAllergies, OTHER_VALUE_BINDINGS.value);
      }

      return true;
    }
  }
};
</script>
